import React, { useState, useEffect } from "react";
import classes from "./ChatContent.module.css";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "./reduxdata/uiSlice";
import clsx from "clsx";
import MsgPair from "./MsgPair";

const ChatContent = (props) => {
  const dispatch = useDispatch();
  const processingRequest = useSelector((state) => state.uiState.processingUserRequest);
  const messages = useSelector((state) => state.chatMessagesState.messages);
  const showTextState = useSelector((state) => state.mainMenuState.showText);
  const isRecording = useSelector((state) => state.uiState.recording);
  const [scondsTillForceStop, setScondsTillForceStop] = useState(5);

  // useEffect(() => {
  //   let interval = null;

  //   if (isRecording && scondsTillForceStop <= 0) {
  //     console.log("force stop");
  //     dispatch(uiActions.setForceStop(true));
  //   } else if (isRecording) {
  //     interval = setInterval(() => {
  //       setScondsTillForceStop((prevState) => prevState - 1);
  //     }, 1000); // Timer interval in milliseconds (1000ms = 1 second)
  //   }

  //   if (!isRecording) {
  //     setScondsTillForceStop(5);
  //   }
  //   // Clean up the timer when the component unmounts or when the dependency changes
  //   return () => {
  //     if (interval) {
  //       clearInterval(interval);
  //     }
  //   };
  // }, [isRecording, scondsTillForceStop]);

  return (
    <div className="relative flex flex-grow flex-shrink basis-0 flex-col py-2 px-3 gap-4 overflow-auto">
      {messages &&
        messages.length > 0 &&
        messages.map((item, index) => {
          let autoPlay = false;
          if (index === messages.length - 1) {
            autoPlay = true;
          }
          return <MsgPair key={index} data={item} showText={showTextState} autoPlay={autoPlay} />;
        })}
      {processingRequest && (
        <div className="font-light text-center italic mt-10 animate-pulse">
          Gimme a few seconds...
        </div>
      )}
      {/* {isRecording && (
        <div className="absolute h-full w-full top-0 left-0 flex flex-col items-center justify-center backdrop-blur-sm bg-[#F2F2F2]/40 text-2xl">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-[7rem] h-[7rem] animate-pulse text-red-900"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
            />
          </svg>
          {scondsTillForceStop}
          <h2 className="text-lg font-bold">Only five second allowed for one record</h2>
        </div>
      )} */}
    </div>
  );
};

export default ChatContent;
