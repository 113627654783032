import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { uiActions } from "./reduxdata/uiSlice";
import { isMobile } from "react-device-detect";

const RecordButton = (props) => {
  const dispatch = useDispatch();
  const forceStop = useSelector((state) => state.uiState.forceStop);
  const micPermission = useSelector((state) => state.uiState.micPermission);

  useEffect(() => {
    if (forceStop) {
      props.stopRecording();
    }
  }, [forceStop]);

  const startRecordHandler = () => {
    console.log("startRecordHandler");
    if (micPermission) {
      props.startRecording();
    } else {
      alert("Mic permission denied.");
    }
  };

  const stopRecordHandler = () => {
    if (forceStop) {
      dispatch(uiActions.setForceStop(false));
    } else if (micPermission) {
      props.stopRecording();
    }
  };

  if (isMobile) {
    return (
      <div className="flex flex-col items-center gap-1">
        <button
          className={
            "bg-slate-50 p-4 rounded-full shadow-recorder " +
            (props.status === "recording"
              ? " text-red-500 "
              : " text-sky-500 hover:text-sky-600 hover:transition-colors")
          }
          onTouchStart={startRecordHandler}
          onTouchEnd={stopRecordHandler}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={"w-11 h-11 " + (props.status === "recording" ? "animate-pulse" : "")}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
            />
          </svg>
        </button>
      </div>
    );
  } else {
    return (
      <div className="flex flex-col items-center gap-1">
        <button
          className={
            "bg-slate-50 p-4 rounded-full shadow-recorder " +
            (props.status === "recording"
              ? " text-red-500 "
              : " text-sky-500 hover:text-sky-600 hover:transition-colors")
          }
          onMouseDown={startRecordHandler}
          onMouseUp={stopRecordHandler}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className={"w-11 h-11 " + (props.status === "recording" ? "animate-pulse" : "")}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
            />
          </svg>
        </button>
      </div>
    );
  }
};

export default RecordButton;
