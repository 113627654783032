import React, { useEffect, useState } from "react";
import Recorder from "./Recorder";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "./reduxdata/uiSlice";
import { chatMessagesActions } from "./reduxdata/chatMessagesSlice";
import { convertBlobUrlToBase64 } from "./Services/AIService";
import { ProcessUserRequest } from "./Services/WebApiService";

const BottomMenu = (props) => {
  const dispatch = useDispatch();
  const [youShouldBeCounting, setYouShouldBeCounting] = useState(false);
  const session = useSelector((state) => state.sessionState.session);

  const handleStop = (blobUrl) => {
    setYouShouldBeCounting(false);
    dispatch(uiActions.setRecording(false));
    dispatch(uiActions.setProcessingUserRequest(true));
    convertBlobUrlToBase64(blobUrl)
      .then((base64String) => {
        ProcessUserRequest({
          SessionId: session.SessionId,
          RoleId: session.RoleId,
          AudioData: base64String,
          UserName: session.UserName,
          Language: session.Language,
        }).then((res) => {
          dispatch(uiActions.setProcessingUserRequest(false));
          if (!res.data.ErrorMsg) {
            dispatch(chatMessagesActions.pushToMessages(res.data.responseData));
          } else {
            console.error(res.data.ErrorMsg);
          }
        });
      })
      .catch((error) => {
        dispatch(uiActions.setProcessingUserRequest(false));
        console.error("Error converting Blob URL to Base64:", error);
      });
  };

  const handleStart = () => {
    console.log("handleStart");
    setYouShouldBeCounting(true);
    dispatch(uiActions.setRecording(true));
  };

  return (
    <div className="flex flex-row items-center justify-center w-full py-4 bg-[#ddddde] rounded-b-xl">
      <Recorder recordStartHandler={handleStart} recordStopHandler={handleStop} />
    </div>
  );
};

export default BottomMenu;
