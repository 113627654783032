import React from "react";
import { getSvgById } from "./Services/SVGService";

const MenuItem = (props) => {
  const { iconId, text, itemId, onItemClicked } = props;

  const onClickHandler = () => {
    onItemClicked(itemId);
  };

  return (
    <div
      onClick={onClickHandler}
      className="flex flex-row gap-1 select-none cursor-pointer
     text-gray-700 text-lg border-b border-gray-400 font-semibold
     hover:text-gray-950 hover:border-gray-700 transition-all"
    >
      {getSvgById(iconId)}
      {text}
    </div>
  );
};

export default MenuItem;
