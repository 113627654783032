import { Button, styled } from "@mui/material";

export const MainButton = styled(Button)(({ theme }) => ({
  color: "#DDDDDD",
  borderRadius: "50px",
  padding: "0.75rem 1.25rem",
  boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.4)",
  backgroundColor: "#7A6BFF",
  "&:hover": {
    backgroundColor: "#4B38FF",
    boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.4)",
  },
  "&:disabled": {
    backgroundColor: "#B4B4B4",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  },
}));

export const YesButton = styled(Button)(({ theme }) => ({
  color: "#DDDDDD",
  borderRadius: "50px",
  padding: "0.75rem 1.25rem",
  boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.4)",
  backgroundColor: "#6957FF",
  "&:hover": {
    backgroundColor: "#5f4ee6",
    boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.4)",
  },
  "&:disabled": {
    backgroundColor: "#B4B4B4",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  },
}));

export const NoButton = styled(Button)(({ theme }) => ({
  color: "#DDDDDD",
  borderRadius: "50px",
  padding: "0.75rem 1.25rem",
  boxShadow: "0px 0px 6px 0px rgba(0,0,0,0.4)",
  backgroundColor: "#FF1E21",
  "&:hover": {
    backgroundColor: "#e61b1e",
    boxShadow: "0px 0px 8px 0px rgba(0,0,0,0.4)",
  },
  "&:disabled": {
    backgroundColor: "#B4B4B4",
    boxShadow: "0px 0px 5px 0px rgba(0,0,0,0.2)",
  },
}));
