import { createSlice } from "@reduxjs/toolkit";

const rolesSlice = createSlice({
  name: "rolesSlice",
  initialState: {
    roles: null,
    selectedRole: null,
  },
  reducers: {
    setRoles(state, action) {
      state.roles = action.payload;
    },
    setSelectedRole(state, action) {
      state.selectedRole = action.payload;
    },
  },
});

export const rolesActions = rolesSlice.actions;
export default rolesSlice;
