import React, { useState, useEffect } from "react";
import MenuItem from "./MenuItem";
import { useSelector, useDispatch } from "react-redux";
import { sessionActions } from "./reduxdata/sessionSlice";
import lengs from "./lengs";
import Picker from "./Pickers/Picker";
import { UpdateLanguage } from "./Services/CookiesService";

/*
{data &&
          data.length > 0 &&
          data.map((item, index) => {
            if (item.Show) {
              return (
                <MenuItem
                  key={index}
                  onClick={onMenuItemClickedHandler.bind(null, item.MenuItemId)}
                >
                  <div
                    style={{ fontFamily: "Ubuntu" }}
                    className="flex flex-row gap-1 w-full transition-all duration-200 text-gray-800 hover:text-blue-800"
                  >
                    {getSvgById(item.SvgId)}
                    <p>{item.Text}</p>
                  </div>
                </MenuItem>
              );
            }
          })}
*/

const MainMenu = (props) => {
  const { data, show, onMenuItemClickedHandler } = props;

  const session = useSelector((state) => state.sessionState.session);
  const dispatch = useDispatch();

  let showHideClass = " transition-[top] duration-150 ease-in top-[0] ";
  if (show) {
    showHideClass = " transition-[top] duration-300 ease-out top-[13.5rem] ";
  }

  const languageSelected = (lang) => {
    dispatch(
      sessionActions.setSession({
        ...session,
        Language: lang.value,
      })
    );

    UpdateLanguage(lang.value);
  };

  const handleClick = (event) => {
    //setAnchorEl(event.currentTarget);
  };

  return (
    <div
      className={
        "absolute flex justify-center rounded-b-xl " +
        showHideClass +
        " left-[1%] w-[98%]  backdrop-blur-sm bg-[#ddddde]/40 shadow-menuShadow translate-y-[-100%] py-4"
      }
    >
      <div className=" flex flex-col gap-4 ">
        <Picker
          data={lengs}
          onItemSelected={languageSelected}
          selectedItemId={lengs.find((x) => x.value === session.Language).id}
          inMenu={true}
        />
        {data &&
          data.length > 0 &&
          data.map((item, index) => {
            if (item.Show) {
              return (
                <MenuItem
                  key={index}
                  itemId={item.MenuItemId}
                  iconId={item.SvgId}
                  text={item.Text}
                  onItemClicked={onMenuItemClickedHandler}
                />
              );
            }
          })}
      </div>
    </div>
  );
};

export default MainMenu;
