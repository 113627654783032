const lengs = [
  { id: "en", value: "English" },
  { id: "fr", value: "French" },
  { id: "gr", value: "German" },
  { id: "hi", value: "Hindi" },
  { id: "it", value: "Italian" },
  { id: "pl", value: "Polish" },
  { id: "pr", value: "Portuguese" },
  { id: "sp", value: "Spanish" },
];

export default lengs;
