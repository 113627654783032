import React from "react";
import { NoButton, YesButton } from "../Buttons";

const TwoOptionsDialog = (props) => {
  const { Title, Message, onOptionSelected } = props;
  return (
    <div className="flex flex-col">
      <h1 className="self-center text-2xl font-bold mb-5">{Title}</h1>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        stroke="currentColor"
        className="w-20 h-20 self-center mb-4 text-red-700 "
      >
        <path
          strokeLinecap="round"
          strokeLinejoin="round"
          d="M9.879 7.519c1.171-1.025 3.071-1.025 4.242 0 1.172 1.025 1.172 2.687 0 3.712-.203.179-.43.326-.67.442-.745.361-1.45.999-1.45 1.827v.75M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9 5.25h.008v.008H12v-.008z"
        />
      </svg>
      <p className="font-medium text-base px-5 text-justify mb-5">{Message}</p>
      <div className="flex flex-row gap-6 self-center mb-3">
        <YesButton onClick={onOptionSelected.bind(null, true)}>Yes</YesButton>
        <NoButton onClick={onOptionSelected.bind(null, false)}>No</NoButton>
      </div>
    </div>
  );
};

export default TwoOptionsDialog;
