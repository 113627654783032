import axios from "axios";

const url = "https://service.evka.app"; //"https://localhost:7291" https://evkawebapi.azurewebsites.net;

export const PostSession = async (data) => {
  return await axios.post(url + "/api/Session/PostSession", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const UpdateSession = async (data) => {
  return await axios.post(url + "/api/Session/UpdateSession", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const UpdateUserName = async (data) => {
  return await axios.post(url + "/api/Session/UpdateUserName", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const ProcessUserRequest = async (data) => {
  return await axios.post(url + "/api/AI/ProcessUserRequest", data, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetHistory = async (sessionId) => {
  return await axios.get(url + "/api/AI/GetHistory?sessionId=" + sessionId);
};

export const ClearHistory = async (sessionId) => {
  return await axios.post(url + "/api/AI/ClearHistory", sessionId, {
    headers: {
      "Content-Type": "application/json",
    },
  });
};

export const GetRoles = async () => {
  return await axios.get(url + "/api/Session/GetRoles");
};
