import { Dialog, DialogTitle, DialogContent, IconButton, styled } from "@mui/material";

const CustomDialog = (props) => {
  const handleClose = () => {
    if (props.onCloseClick) {
      props.onCloseClick(null);
    }
  };

  const CDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiPaper-root": {
      background: "rgb(229 231 235)",
      borderRadius: 12,
      maxWidth: "800px",
    },
    "& .MuiDialogTitle-root": {
      background: "rgb(240 240 240)",
      padding: "4px 8px 4px 8px",
      display: "flex",
      alignItems: "center",
      justifyContent: "space-between",
    },
    "& .MuiDialogContent-root": {
      padding: "0px 8px 8px 8px",
    },
  }));

  return (
    <CDialog aria-labelledby="customized-dialog-title" open={props.isOpen}>
      <DialogTitle>
        <h2></h2>
        <IconButton aria-label="close" onClick={handleClose}>
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            stroke="currentColor"
            className="w-6 h-6"
          >
            <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
          </svg>
        </IconButton>
      </DialogTitle>
      <DialogContent>{props.renderBody && props.renderBody()}</DialogContent>
    </CDialog>
  );
};

export default CustomDialog;
