import React from "react";

const PickerItem = (props) => {
  //hover:bg-blue-300
  return (
    <div
      className={
        " px-3 py-1 cursor-pointer " + (props.inMenu ? " hover:bg-[#c2c2c2]" : " hover:bg-blue-300")
      }
      onClick={props.onItemSelected}
    >
      {props.children}
    </div>
  );
};

export default PickerItem;
