import React, { useState, useEffect } from "react";
import { ClearHistory } from "./Services/WebApiService";
import { useSelector, useDispatch } from "react-redux";
import { chatMessagesActions } from "./reduxdata/chatMessagesSlice";
import { uiActions } from "./reduxdata/uiSlice";
import { mainMenuActions } from "./reduxdata/mainMenuSlice";
import { IconButton } from "@mui/material";
import MainMenu from "./MainMenu";
import CustomDialog from "./Dialogs/CustomDialog";
import TwoOptionsDialog from "./Dialogs/TwoOptionsDialog";
import ukrImg from "./Imgs/ukrain.png";
import engImg from "./Imgs/usa.png";

const forMainMenu = [
  { MenuItemId: "showTextMsg", SvgId: "textmsg", Text: "Show text messages", Show: true },
  { MenuItemId: "showAudioMsg", SvgId: "audiomsg", Text: "Show audio messages", Show: false },
  // { MenuItemId: "changeRole", SvgId: "setingsicon", Text: "Change settings", Show: true },
  { MenuItemId: "refreshHistory", SvgId: "refreshicon", Text: "Clear history", Show: true },
];

const Title = () => {
  const dispatch = useDispatch();
  const [showMenu, setShowMenu] = useState(false);
  const session = useSelector((state) => state.sessionState.session);
  const showTextState = useSelector((state) => state.mainMenuState.showText);
  const [showDialog, setShowDialog] = useState(false);
  const localization = useSelector((state) => state.uiState.localization);

  if (showTextState) {
    forMainMenu[forMainMenu.findIndex((x) => x.MenuItemId === "showTextMsg")].Show = false;
    forMainMenu[forMainMenu.findIndex((x) => x.MenuItemId === "showAudioMsg")].Show = true;
  } else {
    forMainMenu[forMainMenu.findIndex((x) => x.MenuItemId === "showTextMsg")].Show = true;
    forMainMenu[forMainMenu.findIndex((x) => x.MenuItemId === "showAudioMsg")].Show = false;
  }

  const onMenuItemClicked = (id) => {
    menuButtonClicked();
    switch (id) {
      case "showTextMsg":
        dispatch(mainMenuActions.setShowText(true));
        break;
      case "showAudioMsg":
        dispatch(mainMenuActions.setShowText(false));
        break;
      case "changeRole":
        dispatch(uiActions.setShowWelcomPage(true));
        break;
      case "refreshHistory":
        setShowDialog(true);
        break;
      default:
        break;
    }
  };

  const menuButtonClicked = () => {
    setShowMenu((prevState) => !prevState);
  };

  // const onRefreshClicked = () => {

  // };

  const optionSelected = (answer) => {
    if (answer) {
      //dispatch(uiActions.setProcessingUserRequest(true));
      ClearHistory({ SessionId: session.SessionId }).then((response) => {
        //dispatch(uiActions.setProcessingUserRequest(false));
        dispatch(chatMessagesActions.clearMessages());
      });
    }
    setShowDialog(false);
  };

  const dialogClosedByDefaultBtn = () => {
    if (showDialog) {
      setShowDialog(false);
    }
  };

  let dialogRenderBody = null;
  if (showDialog) {
    let msg = [
      "Are you sure you want to clear conversation history?",
      <br />,
      "All messages will be deleted.",
    ];
    dialogRenderBody = () => (
      <TwoOptionsDialog
        Title={"Clear conversation history"}
        Message={msg}
        onOptionSelected={optionSelected}
      />
    );
  }

  const onLocalClicked = () => {
    if (localization === "ukr") {
      dispatch(uiActions.setLocalization("en"));
    } else {
      dispatch(uiActions.setLocalization("ukr"));
    }
  };

  return (
    <div className="z-10 relative">
      <MainMenu show={showMenu} data={forMainMenu} onMenuItemClickedHandler={onMenuItemClicked} />
      <div className="relative flex flex-row p-2 w-full justify-between items-center bg-[#ddddde] font-bold ">
        <IconButton onClick={menuButtonClicked} size="small" sx={{ ml: 2 }}>
          {!showMenu && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-gray-900 font-bold"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5"
              />
            </svg>
          )}
          {showMenu && (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6 text-gray-900 font-bold"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
            </svg>
          )}
        </IconButton>
        {session && session.Language && <h2 className="italic ">{session.Language}</h2>}
        {!session || (!session.Language && <h2 className="italic ">Rachel</h2>)}
        <IconButton onClick={onLocalClicked} size="small">
          {/* {localization === "ukr" && <img className="w-6" src={engImg} alt="Localization flag" />}
          {localization !== "ukr" && <img className="w-6" src={ukrImg} alt="Localization flag" />} */}
        </IconButton>
      </div>
      {showDialog && (
        <CustomDialog
          isOpen={true}
          onCloseClick={dialogClosedByDefaultBtn}
          renderBody={dialogRenderBody}
        />
      )}
    </div>
  );
};

export default Title;
