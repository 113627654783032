import React from "react";
import ChatMessage from "./ChatMessage";

const MsgPair = (props) => {
  const { data, autoPlay, showText } = props;

  return (
    <div className="flex flex-col w-full">
      <ChatMessage
        audioData={data.audioDataFromUser}
        text={data.textFromUser}
        lang={data.originalLanguage}
        fromGpt={false}
        showText={showText}
        autoPlay={false}
      />
      <ChatMessage
        audioData={data.audioDataFromGpt}
        text={data.textFromGpt}
        lang={data.translationLanguage}
        fromGpt={true}
        showText={showText}
        autoPlay={autoPlay}
      />
    </div>
  );
};

export default MsgPair;
