import Cookies from "js-cookie";

export const GetCookies = () => {
  const fromCookies = Cookies.get("rachelApp_Session");
  if (fromCookies) {
    const cookiesObj = JSON.parse(fromCookies);
    UpdateCookies(cookiesObj);
    return cookiesObj;
  }
  return null;
};

export const SetCookies = (userName, selectedRole, lang) => {
  const sessionId = GenerateGuid();
  Cookies.set(
    "rachelApp_Session",
    JSON.stringify({
      SessionId: sessionId,
      UserName: userName,
      RoleId: selectedRole,
      Language: lang,
    }),
    {
      expires: 1,
    }
  );

  return sessionId;
};

export const UpdateCookies = (data) => {
  Cookies.set("rachelApp_Session", JSON.stringify(data), {
    expires: 1,
  });
};

export const UpdateLanguage = (newLang) => {
  const fromCookies = Cookies.get("rachelApp_Session");
  if (fromCookies) {
    const cookiesObj = JSON.parse(fromCookies);
    UpdateCookies({
      ...cookiesObj,
      Language: newLang,
    });
  }
};

const GenerateGuid = () => {
  return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
    var r = (Math.random() * 16) | 0,
      v = c === "x" ? r : (r & 0x3) | 0x8;
    return v.toString(16);
  });
};
