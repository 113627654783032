import { createSlice } from "@reduxjs/toolkit";

const uiSlice = createSlice({
  name: "uiSlice",
  initialState: {
    processingUserRequest: false,
    showWelcomPage: false,
    localization: "ukr",
    recording: false,
    forceStop: false,
    micPermission: false,
  },
  reducers: {
    setProcessingUserRequest(state, action) {
      state.processingUserRequest = action.payload;
    },
    setShowWelcomPage(state, action) {
      state.showWelcomPage = action.payload;
    },
    setLocalization(state, action) {
      state.localization = action.payload;
    },
    setRecording(state, action) {
      state.recording = action.payload;
    },
    setForceStop(state, action) {
      state.forceStop = action.payload;
    },
    setMicPermission(state, action) {
      state.micPermission = action.payload;
    },
  },
});

export const uiActions = uiSlice.actions;
export default uiSlice;
