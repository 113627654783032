import { createSlice } from "@reduxjs/toolkit";

const sessionSlice = createSlice({
  name: "sessionSlice",
  initialState: {
    session: null,
    showNewSessionDialog: false,
  },
  reducers: {
    setSession(state, action) {
      state.session = action.payload;
    },
    setShowDialog(state, action) {
      state.showNewSessionDialog = action.payload;
    },
  },
});

export const sessionActions = sessionSlice.actions;
export default sessionSlice;
