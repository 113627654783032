export function convertBlobUrlToBase64(blobUrl) {
  return new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      const reader = new FileReader();
      reader.onloadend = function () {
        resolve(reader.result);
      };
      reader.onerror = reject;
      reader.readAsDataURL(xhr.response);
    };
    xhr.onerror = reject;
    xhr.open("GET", blobUrl);
    xhr.responseType = "blob";
    xhr.send();
  });
}

export async function getUrlBlob(base64) {
  const base64Response = await fetch(`data:audio/mp3;base64,${base64}`);
  const blob = await base64Response.blob();
  let reader = new FileReader();
  reader.readAsDataURL(blob);
  await new Promise(
    (resolve) =>
      (reader.onload = function () {
        resolve();
      })
  );
  return reader.result;
}
