import React, { useEffect, useState } from "react";
import { Popover } from "@mui/material";
import PickerItem from "./PickerItem";

const PickerModal = (props) => {
  const { open, anchorEl, onClose, data, side, inMenu } = props;
  const [itemsList, setItemsList] = useState(null);

  useEffect(() => {
    if (data !== null) {
      setItemsList(
        data.map((item) => (
          <PickerItem
            key={item.id}
            inMenu={inMenu}
            onItemSelected={pickerItemSelected.bind(null, item)}
          >
            <p>{item.value}</p>
          </PickerItem>
        ))
      );
    }
  }, [data]);

  const pickerItemSelected = (item) => {
    props.itemSelected(item);
  };

  return (
    <Popover
      open={open}
      onClose={onClose}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: "bottom",
        horizontal: side == null ? "center" : side,
      }}
      transformOrigin={{
        vertical: "top",
        horizontal: side == null ? "center" : side,
      }}
      sx={{
        marginTop: "5px",
        ".MuiPopover-paper": {
          background: "#FBFBFD",
          borderRadius: "12px",
          paddingBottom: "0px",
          boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.2)",
        },
      }}
    >
      <div style={{ width: props.currentWidth + "px" }}>
        <ul className=" overflow-auto">{itemsList}</ul>
      </div>
    </Popover>
  );
};

export default PickerModal;
