import React, { useState, useEffect } from "react";
import { IconButton } from "@mui/material";
import PickerModal from "./PickerModal";
import RefResizeObserver from "rc-resize-observer";

const Picker = (props) => {
  const [defaultAnchorEl, setDefaultAnchorEl] = useState(null);
  const [currentWidth, setCurrentWidth] = useState(0);
  const [renderValue, setRenderValue] = useState(null);
  const [selectedItem, setSelectedItem] = useState(null);
  const { onItemSelected, selectedItemId, disabled = false, data, shouldBeClosed, inMenu } = props;

  useEffect(() => {
    if (selectedItemId && data) {
      const found = data.find((x) => x.id === selectedItemId);
      if (found) {
        setSelectedItem(found);
      }
    }
  }, [selectedItemId, data]);

  useEffect(() => {
    if (selectedItem) {
      setRenderValue(selectedItem.value);
    }
  }, [selectedItem]);

  const onOpen = (e) => {
    setDefaultAnchorEl(e.currentTarget);
  };

  const onClose = () => {
    setDefaultAnchorEl(null);
  };

  const handleKeyDown = (e) => {
    e.preventDefault();

    if (e.key === "Enter") {
      onOpen(e);
    }
  };

  if (shouldBeClosed) {
    setDefaultAnchorEl(null);
  }

  const open = !!defaultAnchorEl;

  const pickerResized = (e) => {
    let w = e.width;
    if (w > 10) {
      w = w - 10;
    }
    setCurrentWidth(w);
  };

  const itemSelected = (item) => {
    if (onItemSelected) {
      onItemSelected(item);
    }
    setSelectedItem(item);
    setDefaultAnchorEl(null);
  };

  let pickerStyle =
    "min-w-pickeMinW min-h-pickerMinH flex bg-white shadow-inputShadow items-center justify-between flex-wrap rounded-xl cursor-pointer";
  if (inMenu) {
    pickerStyle =
      "min-w-pickeMinW min-h-pickerMinH flex items-center justify-between flex-wrap rounded-xl cursor-pointer text-lg text-gray-700 border-solid border border-gray-700 font-semibold hover:text-gray-950 hover:border-gray-950";
  }

  return (
    <>
      <RefResizeObserver onResize={pickerResized}>
        <div
          style={{ fontFamily: "Ubuntu" }}
          className={pickerStyle}
          onClick={disabled ? undefined : onOpen}
          tabIndex={disabled ? -1 : 0}
          onKeyDown={handleKeyDown}
        >
          <div className=" py-2 px-3 flex-1 cursor-pointer">
            {renderValue ? renderValue : "Placeholder"}
          </div>
          <IconButton
            disabled={disabled}
            className={open ? "rotate-180" : ""}
            sx={{
              padding: "4px",
            }}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth="1.5"
              stroke="currentColor"
              className="w-6 h-6"
            >
              <path strokeLinecap="round" strokeLinejoin="round" d="M19.5 8.25l-7.5 7.5-7.5-7.5" />
            </svg>
          </IconButton>
        </div>
      </RefResizeObserver>
      <PickerModal
        anchorEl={defaultAnchorEl}
        open={open}
        onClose={onClose}
        currentWidth={currentWidth}
        data={data}
        itemSelected={itemSelected}
        inMenu={inMenu}
      />
    </>
  );
};

export default Picker;
