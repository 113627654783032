import React, { useEffect, useState } from "react";
import { getUrlBlob } from "./Services/AIService";

const ChatMessage = (props) => {
  const { audioData, text, lang, fromGpt, showText, autoPlay } = props;
  const [blobUrl, setBlobUrl] = useState(null);

  useEffect(() => {
    getUrlBlob(audioData).then((res) => {
      setBlobUrl(res);
    });
  }, []);

  return (
    <fieldset
      className={
        " border-0 rounded-xl py-4 max-w-[60%] drop-shadow" +
        (fromGpt
          ? " self-end " + (showText && " pr-3 pl-10 ")
          : " self-start " + (showText && " pl-3 pr-10 ")) +
        (showText && " bg-white")
      }
    >
      <legend
        className={
          "font-semibold italic cursor-none " +
          (fromGpt ? " text-green-500 text-right mr-2" : " text-blue-500 text-left ml-2")
        }
      >
        {lang}
      </legend>
      {showText && <p>{text}</p>}
      {!showText && blobUrl && (
        <audio src={blobUrl} className="appearance-none" autoPlay={autoPlay} controls />
      )}
    </fieldset>
  );
};

export default ChatMessage;
