import React from "react";
import { ReactMediaRecorder } from "react-media-recorder";
import { isMobile } from "react-device-detect";
import RecordButton from "./RecordButton";

//https://www.npmjs.com/package/react-media-recorder-2
const Recorder = (props) => {
  //

  if (isMobile) {
    return (
      <ReactMediaRecorder
        audio
        onStart={props.recordStartHandler}
        onStop={props.recordStopHandler}
        render={({ status, startRecording, stopRecording }) => (
          <div className="flex flex-col items-center gap-1">
            <button
              className={
                "bg-slate-50 p-6 rounded-full shadow-recorder " +
                (status === "recording"
                  ? " text-red-500 "
                  : " text-sky-500 hover:text-sky-600 hover:transition-colors")
              }
              onTouchStart={startRecording}
              onTouchEnd={stopRecording}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={1.5}
                stroke="currentColor"
                className={"w-11 h-11 " + (status === "recording" ? "animate-pulse" : "")}
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
                />
              </svg>
            </button>
            {/* <p className="font-light text-white">{status}</p> */}
          </div>
        )}
      />
    );
  } else {
    return (
      <ReactMediaRecorder
        audio
        onStart={props.recordStartHandler}
        onStop={props.recordStopHandler}
        render={({ status, startRecording, stopRecording }) => (
          <RecordButton
            status={status}
            startRecording={startRecording}
            stopRecording={stopRecording}
          />
        )}
      />
    );
  }
};

export default Recorder;
