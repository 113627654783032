import { createSlice } from "@reduxjs/toolkit";

const mainMenuSlice = createSlice({
  name: "mainMenuSlice",
  initialState: {
    showText: false,
    showRoleSettings: false,
  },
  reducers: {
    setShowText(state, action) {
      state.showText = action.payload;
    },
    setShowRoleSettings(state, action) {
      state.showRoleSettings = action.payload;
    },
  },
});

export const mainMenuActions = mainMenuSlice.actions;
export default mainMenuSlice;
