import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "./reduxdata/uiSlice";
import ChatContent from "./ChatContent";
import BottomMenu from "./BottomMenu";

const FunctionalContainer = (props) => {
  const dispatch = useDispatch();
  const showWelcomPage = useSelector((state) => state.uiState.showWelcomPage);
  const isRecording = useSelector((state) => state.uiState.recording);
  const micPermission = useSelector((state) => state.uiState.micPermission);
  const [scondsTillForceStop, setScondsTillForceStop] = useState(5);

  useEffect(() => {
    let interval = null;

    if (!micPermission) {
      navigator.permissions.query({ name: "microphone" }).then((permission) => {
        if (permission && permission.state === "granted") {
          console.log("mic permission already true");
          dispatch(uiActions.setMicPermission(true));
        } else {
          const permissions = navigator.mediaDevices.getUserMedia({ audio: true, video: false });
          permissions
            .then((stream) => {
              dispatch(uiActions.setMicPermission(true));
            })
            .catch((err) => {
              console.log(`${err.name} : ${err.message}`);
            });
        }
      });
    }

    if (isRecording && scondsTillForceStop <= 0) {
      console.log("force stop");
      dispatch(uiActions.setForceStop(true));
    } else if (isRecording) {
      interval = setInterval(() => {
        setScondsTillForceStop((prevState) => prevState - 1);
      }, 1000); // Timer interval in milliseconds (1000ms = 1 second)
    }

    if (!isRecording) {
      setScondsTillForceStop(5);
    }
    // Clean up the timer when the component unmounts or when the dependency changes
    return () => {
      if (interval) {
        clearInterval(interval);
      }
    };
  }, [micPermission, isRecording, scondsTillForceStop]);

  return (
    <div className={showWelcomPage ? "hidden" : "relative flex flex-col w-full h-full"}>
      <ChatContent />
      <BottomMenu />
      {isRecording && (
        <div className="absolute top-[calc(50%-9rem)] left-[calc(50%-11rem)] w-[22rem] h-[14rem] flex flex-col items-center justify-center backdrop-blur-sm bg-[#F2F2F2]/40 text-2xl rounded-2xl shadow-sm">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="currentColor"
            className="w-[7rem] h-[7rem] animate-pulse text-red-900"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M12 18.75a6 6 0 006-6v-1.5m-6 7.5a6 6 0 01-6-6v-1.5m6 7.5v3.75m-3.75 0h7.5M12 15.75a3 3 0 01-3-3V4.5a3 3 0 116 0v8.25a3 3 0 01-3 3z"
            />
          </svg>
          {scondsTillForceStop}
          <h2 className="text-lg font-bold">Only five second allowed for one record</h2>
        </div>
      )}
    </div>
  );
};

export default FunctionalContainer;
