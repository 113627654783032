import React, { useState, useEffect } from "react";
import Picker from "./Pickers/Picker";
import { MainButton } from "./Buttons";
import { PostSession, UpdateUserName } from "./Services/WebApiService";
import { SetCookies, UpdateCookies } from "./Services/CookiesService";
import { useSelector, useDispatch } from "react-redux";
import { sessionActions } from "./reduxdata/sessionSlice";
import { uiActions } from "./reduxdata/uiSlice";
import CustomDialog from "./Dialogs/CustomDialog";
import TwoOptionsDialog from "./Dialogs/TwoOptionsDialog";
import { ClearHistory } from "./Services/WebApiService";
import { chatMessagesActions } from "./reduxdata/chatMessagesSlice";
import lengs from "./lengs";

// const lengs = [
//   { id: "en", value: "English" },
//   { id: "fr", value: "French" },
//   { id: "gr", value: "German" },
//   { id: "hi", value: "Hindi" },
//   { id: "it", value: "Italian" },
//   { id: "pl", value: "Polish" },
//   { id: "pr", value: "Portuguese" },
//   { id: "sp", value: "Spanish" },
// ];

const WelcomePage = (props) => {
  const { roles } = props;
  const session = useSelector((state) => state.sessionState.session);
  const dispatch = useDispatch();
  const [rolesData, setRolesData] = useState(null);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedLanguage, setSelectedLanguage] = useState(null);
  const [userName, setUserName] = useState("");
  const [showWorning, setShowWorning] = useState(null);
  const [busy, setBusy] = useState(false);
  const [showDialog, setShowDialog] = useState(false);

  useEffect(() => {
    if (roles) {
      setRolesData(
        roles.map((item) => {
          return { id: item.id, value: item.roleName };
        })
      );
    }
    if (session) {
      setUserName(session.UserName);
      if (session.Language) {
        setSelectedLanguage(lengs.find((x) => x.value === session.Language));
      } else {
        setSelectedLanguage(lengs.find((x) => x.id === "pl"));
      }
      if (roles) {
        setSelectedRole(roles.find((x) => x.id === session.RoleId));
      }
    } else {
      setUserName("user1");
      setSelectedRole(roles.find((x) => x.id === 2));
      setSelectedLanguage(lengs.find((x) => x.id === "pl"));
    }
  }, [roles, session]);

  const inputChangeHandler = (event) => {
    setUserName(event.target.value);
  };

  const onLostFocus = () => {
    setShowWorning(!userName);
  };

  const roleSelectedHandler = (role) => {
    setSelectedRole(roles.find((x) => x.id === role.id));
  };
  const languageSelected = (lang) => {
    setSelectedLanguage(lang);
  };

  const onContinueClicked = () => {
    if (session) {
      if (session.RoleId !== selectedRole.id) {
        setShowDialog(true);
      } else {
        processChanges();
      }
    } else {
      const sId = SetCookies(
        userName,
        selectedRole.id,
        selectedLanguage ? selectedLanguage.value : null
      );
      const newSession = {
        SessionId: sId,
        UserName: userName,
        RoleId: selectedRole.id,
        Language: selectedLanguage ? selectedLanguage.value : null,
      };
      dispatch(sessionActions.setSession(newSession));

      setBusy(true);
      PostSession(newSession).then((response) => {
        setBusy(false);

        if (response.status !== 200) {
          console.error("error when posting session id");
        }
      });

      dispatch(uiActions.setShowWelcomPage(false));
    }
  };

  const processChanges = () => {
    if (session.UserName !== userName) {
      setBusy(true);
      UpdateUserName({ SessionId: session.SessionId, UserName: userName }).then((response) => {
        setBusy(false);
        if (response.status !== 200) {
          console.error("error while updating session");
        }
      });
    }
    UpdateCookies({
      SessionId: session.SessionId,
      UserName: userName,
      RoleId: selectedRole.id,
      Language: selectedLanguage && selectedRole.id === 2 ? selectedLanguage.value : null,
    });
    dispatch(
      sessionActions.setSession({
        ...session,
        UserName: userName,
        RoleId: selectedRole.id,
        Language: selectedLanguage && selectedRole.id === 2 ? selectedLanguage.value : null,
      })
    );
    dispatch(uiActions.setShowWelcomPage(false));
  };

  const dialogClosedByDefaultBtn = () => {
    if (showDialog) {
      setShowDialog(false);
    }
  };
  const optionSelected = (answer) => {
    if (answer) {
      ClearHistory({ SessionId: session.SessionId }).then((response) => {
        dispatch(chatMessagesActions.clearMessages());
        processChanges();
      });
    } else {
      if (session.Language) {
        setSelectedLanguage(lengs.find((x) => x.value === session.Language));
      } else {
        setSelectedLanguage(lengs.find((x) => x.id === "pl"));
      }
      if (roles) {
        setSelectedRole(roles.find((x) => x.id === session.RoleId));
      }
    }
    setShowDialog(false);
  };

  let dialogRenderBody = null;
  if (showDialog) {
    let msg = [
      "Are you sure you what to change the Rachel's role?",
      <br />,
      "In this case all previous conversation will be reset.",
    ];
    dialogRenderBody = () => (
      <TwoOptionsDialog
        Title={"Changing Rachel's role"}
        Message={msg}
        onOptionSelected={optionSelected}
      />
    );
  }

  return (
    <div
      className={"flex flex-col items-center " + (busy && " justify-center ") + " h-full w-full"}
    >
      {!busy && (
        <>
          <h1 className="font-extrabold text-transparent mt-5 text-8xl bg-clip-text bg-gradient-to-r from-purple-400 to-pink-600">
            Evka
          </h1>
          <h2 className="font-extrabold text-transparent text-center mb-6 text-4xl bg-clip-text bg-gradient-to-r to-pink-600 from-blue-600">
            Your voice powered translator
          </h2>
          <div className="h-72 flex flex-col items-center self-stretch mx-1 md:mx-9 ">
            {!session && (
              <h1 className=" font-semibold text-5xl text-gray-600 bg-transparent mb-8 ">
                Welcom!
              </h1>
            )}
            <div style={{ width: "20rem" }} className="flex flex-col gap-5 mb-10">
              {/* <div className="flex flex-col gap-1">
                <h2 style={{ marginLeft: "-10px" }} className="text-gray-600 font-medium">
                  User name
                </h2>
                <input
                  className={
                    "py-3 px-2 " +
                    (showWorning && "border-2 animate-pulse border-red-900") +
                    " rounded-xl text-center text-lg shadow-inputShadow focus:outline-none focus:border-0 focus:animate-none focus:shadow-inputInnerShadow min-w-forInput"
                  }
                  onBlur={onLostFocus}
                  type="text"
                  autoFocus={true}
                  placeholder="Please, specify any user name"
                  onChange={inputChangeHandler}
                  value={userName}
                />
              </div> */}
              <div className="flex flex-col gap-1">
                <h2 style={{ marginLeft: "-10px" }} className="text-gray-600 font-medium">
                  Role
                </h2>
                {selectedRole && (
                  <Picker
                    data={rolesData}
                    onItemSelected={roleSelectedHandler}
                    selectedItemId={selectedRole.id}
                  />
                )}
              </div>
              {selectedRole && selectedRole.id === 2 && selectedLanguage && (
                <div className="flex flex-col gap-1">
                  <h2 style={{ marginLeft: "-10px" }} className="text-gray-600 font-medium">
                    Language
                  </h2>
                  <Picker
                    data={lengs}
                    onItemSelected={languageSelected}
                    selectedItemId={selectedLanguage.id}
                  />
                </div>
              )}
              <div className=" p-5 shadow-inputInnerShadow bg-white rounded-xl ">
                {selectedRole && <p className="text-justify italic">{selectedRole.description}</p>}
              </div>
            </div>
            <MainButton
              className="text-3xl"
              sx={{
                fontSize: "1.875rem",
                lineHeight: "2.25rem",
              }}
              disabled={!userName}
              onClick={onContinueClicked}
            >
              Continue
            </MainButton>
          </div>
        </>
      )}
      {busy && (
        <h1 className="text-2xl font-bold text-center italic animate-pulse">
          App is getting ready...
        </h1>
      )}
      {showDialog && (
        <CustomDialog
          isOpen={true}
          onCloseClick={dialogClosedByDefaultBtn}
          renderBody={dialogRenderBody}
        />
      )}
    </div>
  );
};

export default WelcomePage;
