import { createSlice } from "@reduxjs/toolkit";

const blobSlice = createSlice({
  name: "blobSlice",
  initialState: {
    blobUrl: null,
  },
  reducers: {
    setBlobUrl(state, action) {
      state.blobUrl = action.payload;
    },
  },
});

export const blobActions = blobSlice.actions;
export default blobSlice;
