import { createSlice } from "@reduxjs/toolkit";

const chatMessagesSlice = createSlice({
  name: "chatMessagesSlice",
  initialState: {
    messages: [],
  },
  reducers: {
    pushToMessages(state, action) {
      state.messages.push(action.payload);
    },
    clearMessages(state, action) {
      state.messages = [];
    },
  },
});

export const chatMessagesActions = chatMessagesSlice.actions;
export default chatMessagesSlice;
