import { configureStore } from "@reduxjs/toolkit";
import blobSlice from "./blobSlice";
import uiSlice from "./uiSlice";
import sessionSlice from "./sessionSlice";
import chatMessagesSlice from "./chatMessagesSlice";
import mainMenuSlice from "./mainMenuSlice";
import rolesSlice from "./rolesSlice";

const store = configureStore({
  reducer: {
    blobState: blobSlice.reducer,
    uiState: uiSlice.reducer,
    sessionState: sessionSlice.reducer,
    chatMessagesState: chatMessagesSlice.reducer,
    mainMenuState: mainMenuSlice.reducer,
    rolesState: rolesSlice.reducer,
  },
});

export default store;
