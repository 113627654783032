import React, { useState, useEffect } from "react";
import FunctionalContainer from "./FunctionalContainer";
import { useSelector, useDispatch } from "react-redux";
import { uiActions } from "./reduxdata/uiSlice";
import { sessionActions } from "./reduxdata/sessionSlice";
import { rolesActions } from "./reduxdata/rolesSlice";
import { chatMessagesActions } from "./reduxdata/chatMessagesSlice";
import { GetCookies, SetCookies } from "./Services/CookiesService";
import Title from "./Title";
import { PostSession, UpdateSession, GetHistory, GetRoles } from "./Services/WebApiService";
import WelcomePage from "./WelcomePage";

const MainContainer = (props) => {
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.rolesState.roles);
  const showWelcomPage = useSelector((state) => state.uiState.showWelcomPage);
  const [cookiesChecked, setCookiesChecked] = useState(false);

  // useEffect(() => {
  //   console.log("Component mounted");
  //   window.addEventListener("keydown", onKeyDownHandler);
  //   window.addEventListener("keyup", onKeyUpHandler);

  //   return () => {
  //     window.removeEventListener("keydown", onKeyDownHandler);
  //     window.removeEventListener("keyup", onKeyUpHandler);
  //   };
  // }, []);

  useEffect(() => {
    dispatch(uiActions.setLocalization("ukr"));
    if (!roles) {
      GetRoles().then((response) => {
        if (response.status === 200) {
          dispatch(rolesActions.setRoles(response.data.roles));
          dispatch(rolesActions.setSelectedRole(response.data.roles[0]));
        }
      });
    } else {
      if (!cookiesChecked) {
        let cookies = GetCookies();
        if (!cookies) {
          dispatch(uiActions.setShowWelcomPage(true));
          setCookiesChecked(true);
        } else {
          dispatch(
            sessionActions.setSession({
              SessionId: cookies.SessionId,
              UserName: cookies.UserName,
              RoleId: cookies.RoleId,
              Language: cookies.Language,
            })
          );

          UpdateSession({ SessionId: cookies.SessionId }).then((response) => {
            if (response.status != 200) {
              console.error("error while updating session");
            } else {
              GetPrevMessages(cookies.SessionId);
            }
          });
          setCookiesChecked(true);
        }
      }
    }
  }, [roles, cookiesChecked]);

  const GetPrevMessages = (sessionId) => {
    GetHistory(sessionId).then((response) => {
      if (response.data) {
        response.data.forEach((item) => {
          dispatch(chatMessagesActions.pushToMessages(item));
        });
      }
    });
  };

  // const onKeyDownHandler = (e) => {
  //   if (!showWelcomPage && cookiesChecked) {
  //     if (e.keyCode === 32) {
  //       console.log("space down");
  //     }
  //   }
  // };
  // const onKeyUpHandler = (e) => {
  //   if (!showWelcomPage && cookiesChecked) {
  //     if (e.keyCode === 32) {
  //       console.log("space up");
  //     }
  //   }
  // };

  return (
    <div className="flex h-screen flex-col items-center overflow-hidden">
      <div className="flex flex-col h-mainHeight bg-[#FBFBFD] shadow-mainShadow max-w-screen-lg w-[95%] rounded-b-xl">
        {!showWelcomPage && cookiesChecked && <Title />}
        {!showWelcomPage && cookiesChecked && <FunctionalContainer />}
        {showWelcomPage && <WelcomePage roles={roles} />}
        {!roles && (
          <h1 className="text-xl font-bold text-center italic  animate-pulse">
            App is getting ready...
          </h1>
        )}
      </div>
    </div>
  );
};

export default MainContainer;
